@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@500;700&display=swap');
@layer base {
    html {
      font-family: Manrope, system-ui, sans-serif;
    }

    select {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSI2IiB2aWV3Qm94PSIwIDAgMjAgMTIiIGZpbGw9Im5vbmUiPgogICAgPHBhdGggZD0iTTEwIDEyTDAgMS45OTk5OEwxLjQgMC41OTk5NzZMMTAgOS4xOTk5OEwxOC42IDAuNTk5OTc2TDIwIDEuOTk5OThMMTAgMTJaIiBmaWxsPSIjNTI3MzhGIiAvPgo8L3N2Zz4=");
      background-size: 10px;
    }
  }

@layer utilities {
  .transition-default {
    @apply transition-all ease-in duration-200;
  }
}

@layer components {
  .default-tooltip{
    z-index: 100;
    @apply text-base rounded text-left bg-navy-dark !important;
  }
}
